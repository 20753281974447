<template>
	<div class="bg-paper">
		<div class="loginContent">
			<img class="logo" src="../../res/img/logo.png">
			<!-- 登陆方式 -->
			<div class="login_type">
				<div :class="psw_show?'login_chang':'login_type_tle' " @click="psw_show = !psw_show">
					手机号登陆
				</div>
				<div :class="!psw_show?'login_chang':'login_type_tle' " @click="psw_show = !psw_show">
					密码登陆
				</div>
			</div>
			<!-- 登录表单 -->
			<el-form status-icon label-width="0px" label-position="left" style="margin-top:20px">
				<el-form-item prop="tel">
					<el-input v-model="tel" placeholder="请输入手机号" clearable>
					</el-input>
				</el-form-item>
				<!-- <el-button class="btn_left" size="mini" type="text" @click="psw_show = !psw_show">{{psw_show ? '密码登陆' :'验证码登陆'}}</el-button> -->
				<!-- 密码登陆 -->
				<el-form-item prop="check_code" v-if="!psw_show">
					<el-input v-model="login_pwd" type="password" :show-password="true" placeholder="请输入登录密码" clearable  class="input">
						<!-- <template slot="prepend">登录密码</template> -->
					</el-input>
				</el-form-item>
				<!-- 验证码登陆 -->
				<el-form-item prop="check_code" v-if="psw_show"  >
					<div class="verifyTips_type" style="display: flex;border-bottom: 1px solid #0261d5;">
						<el-input v-model="check_code" placeholder="请输入验证码" clearable class="input">
							<!-- <template slot="prepend">验证码</template> -->
						</el-input>
						<div class="verifyTips">
							<button  type="button" @click="get_verify" class="button">{{verifyTips}}</button>
						</div>
					
					</div>
				
				</el-form-item>
			</el-form>
		
			<div class="sub_btn">
				<img class="btn" @click="login" src="../../res/img/btn.png">
				<!-- <el-button @click="login" style="width: 100%;" type="primary">立即登录</el-button> -->
			</div>
		</div>
	</div>
</template>

<script>
	import {mapMutations} from 'vuex'
	// import bg from '@/my/bg.js'
	export default {
		data() {
			return {
				tel: '',
				check_code: '',
				sec:60,
				verifyTips: '获取验证码',

				login_lock:false,
				check_code_lock:false,
				psw_show:true,//登陆方式(true:短信验证码,false:密码)
				login_pwd:'',//密码

				// login_type:'1',//登陆方式 默认手机号
			}
		},
		created() {
			
			// bg.init()
		},
		methods: {
			
			...mapMutations(["end_of_login"]),
			
			//倒计时
			inverted_time(){
				
			},
			//登录
			login(){

				//是否锁定
				if(this.login_lock){
					this.$my.other.msg({
						type:'warning',
						str:'登陆中,不要重复点击',
					})
					return;
				}

				//电话号码校验
				if(!this.$my.check.is_tel(this.tel)){
					this.$my.other.msg({
						type:'warning',
						str:'电话号码格式有误',
					})
					return;
				}
				//通过验证码登录
				if(this.psw_show){
					//验证码校验
					if(!(this.$my.check.is_num(this.check_code)&&this.check_code.length==4)){
						this.$my.other.msg({
							type:'warning',
							str:'验证码只能是4位数字',
						})
						return;
					}
				//通过密码登录
				}else if(!this.psw_show){
					//密码校验
					if(!this.$my.check.is_login_pwd(this.login_pwd)){
						this.$my.other.msg({
							type:'warning',
							str:'密码需包含字母，符号或者数字中至少两项且长度超过6位数，最多不超过16位数',
						})
						return;
					}
				}


				//登录
				this.$my.net.req({
					data:{
						mod:'admin',
						ctr:'login_by_admin',
						tel:this.tel,
						ide:this.check_code,
						pwd:this.login_pwd
					},
					callback:(data)=>{
						
						//缓存用户数据
						this.end_of_login({
							is_login:1,
							user_info:data.user_info
						});

						this.$router.push('/pages');

						//尝试取出期望抵达页面
						//let old_path=this.$my.cache.get('_before_login_to_page')
						
						//去哪?
						// if(old_path){
						//	 this.$router.push(old_path.fullPath);
						// }else{
						//	 this.$router.push('/pages/cargo/cargo_creat');
						// }
					}
				});
			},
			//获取验证码
			get_verify(){

				//是否锁定
				if(this.check_code_lock){
					this.$my.other.msg({
						type:'warning',
						str:'60秒内不能重复获取验证码',
					})
					return;
				}

				//电话号码校验
				if(!this.$my.check.is_tel(this.tel)){
					this.$my.other.msg({
						type:'warning',
						str:'电话号码格式有误',
					})
					return;
				}

				//发送中...
				this.verifyTips="发送中...";
				//发送请求
				this.$my.net.req({
					take_over_control:1,
					data:{
						mod:'admin',
						ctr:'send_ide_for_login_by_admin',
						tel:this.tel
					},
					callback:(data)=>{
					this.$my.other.msg({
						type:'success',
						str:data.error_info,
					   })
						//锁定发送按钮
						this.check_code_lock=true;

						this.verifyTips=this.sec+"S";
						
						//开始倒计时
						this.interval_id=setInterval(()=>{
							this.verifyTips--
							this.sec--;
							this.verifyTips=this.sec+"S";
							if(this.sec==0){
								this.check_code_lock=false;
								this.sec=60;
								this.verifyTips="重新发送";
								clearInterval(this.interval_id)
							}
						},1000);
					}
				});
			},
		}
	}
</script>
<style lang="scss" scoped>
	.bg-paper{
		width: 100%;
		height: 100%;
		position: fixed;
		left: 0;
		top: 0;
		background-color: #666;
		background: url(../../res/img/bg-paper.jpg) no-repeat 50%;
		background-size: cover;
	}

	.loginContent {
		width: 350px;
		position: absolute;
		top: 30%;
		left: calc(50% - 170px);

		.logo{
			width: 90px;
			
			display: block;
			margin: auto;
			margin-bottom: 50px;
		}
		
	}

	.verifyTips_type{
		.verifyTips{
			.button{
				background-color: transparent;
				border: none;
				color: #ffffff;
				border-left: 1px solid #0261d5;
				height: 60%;
				width: 120px;
			}
		}
		/deep/ .el-input__inner {
			background-color: transparent;
			border: none;
			// border-bottom: 1px solid #0261d5;
			color: #0261d5;
		}
	}
	
	.bottom_links{
		
		padding: 0 10px;

		.links{
			margin-top: 10px;
			display: flex;
			flex-wrap: wrap;
			.item{
				font-size: 12px;
			}
		}
	}

	.sub_btn{
		margin-top: 40px;
		cursor: pointer;
		.btn{
			width: 100%;
		}
	}
	/deep/ .el-input__inner {
		background-color: transparent;
		border: none;
		border-bottom: 1px solid #0261d5;
		color: #0261d5;
	}
	.login_type{
		display: flex;
		justify-content: space-between;
		font-size: 20px;
		color: #ffffff;
	}
	.login_chang{
	   color: #0261d5;
		
	}
	.login_type_tle{
       color: #ffffff;
	}
</style>